<template>
  <header id="header">
    <img id="img-back-top" alt="" src="../assets/strEngModel.jpeg">
    <div id="logo">
        <img id="img-logo" alt="STM logo" src="../assets/logoSTMdraft.png">
        <div>
            <p>STM STRUCTURAL DESIGN GROUP</p>
            <div id="contact-home-mobile">
                <div>tel:&nbsp;<span>0748-600393</span></div>
                <div>email:&nbsp;<span>stmstructuraldesign@gmail.com</span></div>
            </div>
        </div>
    </div>
    <nav id="nav-desktop">
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">Despre</router-link></li>
        <li><router-link to="/portofoliu">Portofoliu</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
      <div id="contact-home">
        <a href="tel:0748-600393">tel: 0748-600393&nbsp;</a>&nbsp;/<a href="mailto:stmstructuraldesign@gmail.com">email: stmstructuraldesign@gmail.com</a>
      </div>
    </nav>
    <nav id="nav-mobile">
        <button id="nav-button" @click="openMenu">
            <span class="top"></span>
            <span></span>
            <span class="bottom"></span>
            <ul id="menu-mobile" v-show="menuOpen">
                <li @click="selectPage('home')" v-bind:class="{'active-link': selectedPage.home}"><router-link to="/">&#9632;&nbsp;&nbsp;Home</router-link></li>
                <li @click="selectPage('about')" v-bind:class="{'active-link': selectedPage.about}"><router-link to="/about">&#9632;&nbsp;&nbsp;Despre</router-link></li>
                <li @click="selectPage('portofoliu')" v-bind:class="{'active-link': selectedPage.portofoliu}"><router-link to="/portofoliu">&#9632;&nbsp;&nbsp;Portofoliu</router-link></li>
                <li @click="selectPage('contact')" v-bind:class="{'active-link': selectedPage.contact}"><router-link to="/contact">&#9632;&nbsp;&nbsp;Contact</router-link></li>
            </ul>
        </button>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data () {
    return {
        menuOpen: false,
        selectedPage: {
            home: true,
            about: false,
            portofoliu: false,
            contact: false
        },
    }
  },
  methods: {
    openMenu: function () {
        this.menuOpen = !this.menuOpen;
    },
    selectPage: function (idPage) {
        if (this.selectedPage[idPage])
            return;

        for (let key in this.selectedPage) {
            
            if (key == idPage)
                this.selectedPage[key] = true;
            else
                this.selectedPage[key] = false; 
        }
    },
  }
}
</script>


<style scoped>

    #header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 0.5px solid #ccc;
        padding: 5px 10px;
        position: relative;
        max-height: 150px;
    }

    #logo {
        display: flex;
        flex-direction: row;
    }

    #img-logo {
        height: 85px;
        max-height: 85px;
        margin-right: 5px;
    }

    #img-back-top {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0.4;
        height: 100%;
    }

    #header p {
        margin-left: 1rem;
        font-size: 18pt;
        font-weight: bold;
        font-style: italic;
        color: #329965;
    }

    #contact-home-mobile {
        display: none;
    }

    #nav-desktop {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #nav-desktop ul{
        list-style: none;
    }

    #nav-desktop ul li {
        display: inline-flex;
        margin-left: 1rem;
    }

    #nav-desktop a {
        font-weight: bold;
        color: #329965;
        margin-left: 1rem;
        text-decoration: none;
    }

    #nav-desktop a:hover {
        text-decoration: underline;
    }

    #nav-desktop a.router-link-exact-active {
        color: #ff6500;
    }

    #nav-mobile {
        display: none;
    }

    #contact-home {
        display: flex;
        align-self: flex-end;
        color: #ff6500;
        font-size: 10pt;
    }

    #contact-home span, #contact-home-mobile span {
        color: #898172;
    }

    @media screen and (max-width: 1366px) {

        #img-logo {
            height: 75px;
            max-height: 75px;
        }

        #header p {
            font-size: 14pt;
        }
    }

    @media screen and (max-width: 768px) {

        #header {
            max-height: 80px;
        }

        #header p {
            font-size: 12pt;
        }

        #contact-home-mobile {
            display: flex;
        }
        
        #nav-desktop {
            display: none;
        }

        #nav-mobile {
            display: flex;
        }

        #nav-button {
            display: block;
            width: 25px;
            height: 22px;
            background-color: rgb(133, 25, 25);
            border: none;
            border-radius: 3px;
            align-self: flex-end;
            position: relative;
        }

        #nav-button span {
            display: block;
            width: 13px;
            height: 1px;
            background-color: white;
            top: 0;
        }

        #nav-button .top {
            margin-bottom: 5px;
        }

        #nav-button .bottom {
            margin-top: 5px;
        }

        #menu-mobile {
            position: absolute;
            top: 10px;
            right: 0;
            background-color: lightgrey;
            border-radius: 5px;
            padding-inline-start: 0;
            text-align: left;
        }

        #menu-mobile li {
            padding: 10px;
            list-style-type: none;
            margin: 0;
        }

        #menu-mobile .active-link {
            background-color: grey;
        }

        #menu-mobile a:visited {
            color: #329965;
        }

        #menu-mobile a.router-link-exact-active {
            color: #ff6500;
        }

        #contact-home {
            display: none;
        }

        #contact-home-mobile {
            display: block;
            color: #ff6500;
            font-size: 10pt;
        }

    }

</style>