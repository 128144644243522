<template>
  <footer id="footer">
    <p>Copyright &copy; {{currentYear}} {{companyName}} All rights reserved</p>
  </footer>
</template>

<script>
export default {
    data() {
       return {
        companyName: 'STM Structural Design Group S.R.L.',
        currentYear: new Date().getFullYear()
       } 
    },
}
</script>

<style scoped>
    #footer p {
        font-size: 9pt;
        text-align: right;
        opacity: 0.7;
        margin-right: 1rem;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px) {
      #footer p {
          font-size: 6pt;
      }      
    }
</style>